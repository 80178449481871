<template>
  <div class="reserve-waitboard-wrap" style="display: block;">
    <div style="width: 25%; float: left; background-color: #111111;">
      <table id="wait-list-table" style="color: #ffffff; width: 100%; text-align: center; border-collapse: collapse;">
        <thead>
          <tr>
            <th colspan="2"
              style="border-bottom: 2px solid rgba(255, 255, 255, .7); height: 95px; font-size: 45px; color: #fbceb1;">현재
              대기 {{
                WAIT_TEAM }}명</th>
          </tr>
          <tr style="height: 60px;">
            <th
              style="border-bottom: 2px solid rgba(255, 255, 255, .7); border-right: 1px solid rgba(255, 255, 255, .6); font-size: 35px; width: 50%;">
              상담석</th>
            <th
              style="border-bottom: 2px solid rgba(255, 255, 255, .7); border-left: 1px solid rgba(255, 255, 255, .6); font-size: 35px; width: 50%;">
              대기번호</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(wait, index) in WAIT_LIST.slice(0, 7)" style="height: 14%;"
            :style="{ color: (index === 0 && WAIT_LIST[0].WAIT_NUM === WAIT_NUM && WAIT_LIST[0].WAIT_CS_NUM === WAIT_CS_NUM) ? 'skyblue' : '#ffffff' }">
            <td v-if="wait && wait.WAIT_CS_NUM !== undefined"
              style="border-top: 1px solid rgba(255, 255, 255, .5); border-right: 1px solid rgba(255, 255, 255, .5); height: 100px; font-size: 65px;">
              {{ wait.WAIT_CS_NUM }}
            </td>
            <td v-else
              style="border-top: 1px solid rgba(255, 255, 255, .5); border-right: 1px solid rgba(255, 255, 255, .5); height: 100px;">
            </td>

            <td v-if="wait && wait.WAIT_NUM !== undefined"
              style="border-top: 1px solid rgba(255, 255, 255, .5); border-left: 1px solid rgba(255, 255, 255, .5); height: 100px; font-size: 65px;">
              {{ wait.WAIT_NUM }}
            </td>
            <td v-else
              style="border-top: 1px solid rgba(255, 255, 255, .5); border-left: 1px solid rgba(255, 255, 255, .5); height: 100px;">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="width: 75%; float: left;">
      <div class="reserve-waitboard-print">
        <span class="reserve-waitboard-print--title">{{ WAIT_NUM === 0 ? '대기중입니다' : '대기번호' }}</span>
        <strong class="reserve-waitboard-print--num">{{ WAIT_NUM === 0 ? '' : WAIT_NUM }}</strong>
        <p class="reserve-waitboard-print--text" v-if="WAIT_CS_NUM === 0 ? false : true" style="font-size: 4.5vw">
          지금
          <strong style="font-size: 7vw">상담석 {{ WAIT_CS_NUM }}번</strong>으로 오십시오
        </p>
      </div>
    </div>


  </div>
</template>

<script>
import { stompUtil } from "@/store/stomp-util.js";

export default {
  name: 'MENU_CAP0510',
  components: {},
  metaInfo() {
    return {
      title: "대기현황",
    }
  },
  props: {
  },
  data() {
    return {
      WAIT_TEAM: 0, //대기 팀 건수
      WAIT_NUM: 0, //대기 번호
      WAIT_CS_NUM: 0, //상담석 번호
      CUST_LIST: [],
      CNSLR_CUST_LIST: [],
      REQ_LIST: [],
      IS_ACTIVE: false,
      BUSS_CODE: this.$route.query.buss_code.replace(/[\"\']/gi, ''),
      CMP_ID: this.$route.query.cmp_id.replace(/[\"\']/gi, ''),
      CMP_NAME: this.$route.query.cmp_name.replace(/[\"\']/gi, ''),

      // 음성 재생 데이터
      voiceList: [],
      greetingSpeech: new window.SpeechSynthesisUtterance(),
      synth: window.speechSynthesis,
      voiceBool: true,

      WAIT_LIST: [{}, {}, {}, {}, {}, {}, {}]
    }
  },
  mounted() {
    this.init();

    // 음성 목소리 list 추출
    this.voiceList = this.synth.getVoices();
    this.synth.onvoiceschanged = () => {
      this.voiceList = this.synth.getVoices();
    };
  },
  computed: {
    initHeaders() {
      return {
      };
    },
    paramData() {
      return {
      };
    }
  },

  methods: {
     async init() {
      
      //최초
      this.boardSelect();

    },

    async boardSelect() {

    let requestData = {
        headers: {},
        sendData: {},
      };
      
    requestData.headers["URL"] = "/api/hlw/campaign/work/manage/select";
    requestData.headers["SERVICE"] = "hlw.campaign.work.manage";
    requestData.headers["METHOD"] = "list";
    requestData.headers["ASYNC"] = false;
    requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
    requestData.sendData["CMP_ID"] = this.CMP_ID;

    //데이터 전송
    let response = await this.common_postCall(requestData);

    //에러 처리
    if (response.HEADER.ERROR_FLAG) {
      this.RESULT_MODE = 1;

      let msg = '관리자에게 문의해주세요.';
      if (!this.mixin_isEmpty(response.HEADER.MSG)) {
        msg = response.HEADER.MSG;
      }
      this.common_alert(msg, "error");
      return;
    }

    //웹소켓일 경우, Queue라는 그릇에다가 데이터를 던지면 반응해서 바로 그 데이터의 상태를 판단한다.
    //api로 변경하게 되면, Queue라는 그릇이 없어지므로 반응을 할 데이터를 판별하기 위해서 CALL_FLAG와 ACT로 판별하게 된다!

    // 대기중인 고객 목록이 있으면 -> 대기고객 수 표시
    let cnt = 0;
    if(response.waitingCountData.length > 0) {
      cnt = (response.waitingCountData[0].WAITING_STAFF_COUNT);
    }
    this.WAIT_TEAM = cnt;

    //상담중인 고객 목록
    const CNSLR_CUST_LIST = response.cnslrCustDatas; 

    if (CNSLR_CUST_LIST != null && CNSLR_CUST_LIST != "" && CNSLR_CUST_LIST != []) {

      for(let index = 0; index < CNSLR_CUST_LIST.length; index++) {
        const thisData = CNSLR_CUST_LIST[index];

        //다음상담호출이라면 -> WAIT_LIST에 넣고, BOARD에 띄우기(fnVoice 호출)
        if(!this.mixin_isEmpty(thisData.ACT)) {
          if(thisData.ACT == 'CALL') {

            this.WAIT_NUM = thisData.CNSL_WAIT_NUM; //고객 대기번호
            this.WAIT_CS_NUM = thisData.CNSL_SEAT_NO; //상담사 좌석 번호
            //this.WAIT_TEAM = thisData.WAITING_STAFF_COUNT; //대기중인 고객 카운트

            if (this.WAIT_LIST.length >= 7) {
              this.WAIT_LIST.pop();   //배열의 길이가 7보다(최대치) 높을때 마지막 값 삭제
            }
            this.WAIT_LIST.unshift({ WAIT_CS_NUM: this.WAIT_CS_NUM, WAIT_NUM: this.WAIT_NUM });

            await this.fnVoice();
            this.fnAfterVoice(thisData.CUST_CNSL_ID);
            console.log('CALL:', thisData);

          } else if(thisData.ACT == 'BOARD') { //재호출이라면 -> BOARD에 띄우기(fnVoice 호출)
            this.WAIT_NUM = thisData.CNSL_WAIT_NUM; //고객 대기번호
            this.WAIT_CS_NUM = thisData.CNSL_SEAT_NO; //상담사 좌석 번호
            
            await this.fnVoice();
            this.fnAfterVoice(thisData.CUST_CNSL_ID);
            console.log('BOARD', thisData);
          }
        }
      }

    }

    requestData = null;
    requestData = null;

	//3초마다 반복
    setTimeout(this.boardSelect, 3000);

	},

    async fnVoice() {
      if (!this.mixin_isEmpty(this.WAIT_CS_NUM) && !this.mixin_isEmpty(this.WAIT_NUM)) {

        let greetingSpeech = new window.SpeechSynthesisUtterance();
        greetingSpeech.text = '대기번호' + this.WAIT_NUM + '번 고객님 상담석' + this.WAIT_CS_NUM + '번으로 와주시기 바랍니다';
        greetingSpeech.synth = window.speechSynthesis,
        greetingSpeech.voiceBool = true;
        
        let synth = window.speechSynthesis;

        let index = this.voiceList.findIndex(e => e.name === 'Google 한국의');
        greetingSpeech.voice = this.voiceList[index];

        synth.speak(greetingSpeech);

        greetingSpeech = null;
        synth = null;
        index = null;
      }
    },

    fnCnslBoardCall(type) {
      if (stompUtil.isConnected()) {
        var sendData = {
          ACT: type,
          CMP_ID: this.CMP_ID,
          BUSS_CODE: this.BUSS_CODE,
        };
        var sendDataStr = JSON.stringify(sendData);
        stompUtil.stompClient.send("/wait/buss/counsel/board", sendDataStr);
      }
    },

	//전광판에 표시 후(voice 후) ACT를 초기화한다.
    fnAfterVoice(custCnslId) {
      let requestData = {
        headers: {},
        sendData: {},
      };
      
    requestData.headers["URL"] = "/api/hlw/campaign/work/manage/afterVoice";
    requestData.headers["SERVICE"] = "hlw.campaign.work.manage";
    requestData.headers["METHOD"] = "list";
    requestData.headers["ASYNC"] = false;
    requestData.sendData["CUST_CNSL_ID"] = custCnslId;

    //데이터 전송
    let response = this.common_postCall(requestData);

    }

  },
}
</script>

<style>
.reserve-waitboard-wrap {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
}
</style>